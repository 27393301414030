<template>
    <v-container>
        <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
        <div v-else class="message-container">
            <div class="message-title">
            <h2>{{meeting.title}}</h2>
            <h3 class="grey-text">
                La reunión podrá iniciarse el {{formattedStartUrlAvailableDate}}.
            </h3>
            <h4>
                Para más información podés comunicarte con el administrador.
            </h4>
            </div>
        </div>
    </v-container>
</template>

<script>
import utils from '@/utils/utils';
import { mapActions, mapMutations } from 'vuex';

export default {
    name: 'StartMeetingView',
    data: () => ({
        loading: true,
        meeting: null,
    }),
    async created() {
        try {
            const startUrl = (await this.fetchMeetingStartUrl({meetingId: this.meetingId, token: this.token})).data;
            location.href = startUrl;
            
        } catch (err) {
            if (err?.response) {
                const status = err.response.status;
                if (status === 422) {
                    this.loading = false;
                    this.meeting = err.response.data.details;
                } else if (status === 403) {
                    this.$notifications.showError('No tiene permisos para obtener el enlace.');
                } else if (status === 404) {
                    this.$notifications.showError('No se encontró la reunión.');
                }
            } else {
                this.$notifications.showError('Se ha producido un error. Intente de nuevo más tarde.');
            }
            

        }
    },
    computed: {
        meetingId() {
            return this.$route.params.id;
        },
        token() {
            return this.$route.query.token;
        },
        showStartUrlDate() {
            return this.meeting?.showStartUrlDate && new Date(this.meeting.showStartUrlDate);
        },
        formattedStartUrlAvailableDate() {
            return this.showStartUrlDate && utils.formatDate(this.showStartUrlDate, 'DD/MM/YYYY hh:mm a');
        },

    },
    methods: {
        ...mapActions(['fetchMeetingStartUrl']),
        ...mapMutations(['setLoading']),
    },
};
</script>

<style lang="scss" scoped>

.message-title {
  margin-bottom: 10px !important;
  padding-bottom: 25px !important;
  padding-top: 10px !important;
}

.message-container {
  text-align: center;
  margin-top: 13%;
}

.grey-text {
  color: grey;
}

</style>
